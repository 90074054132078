import { combineReducers } from "redux";
import { fetch, userPreferences, workingModes } from '@nco-b2c-react/common-utilities';
import { WebExtReducer as webExt } from '@nco-b2c-react/component-web-ext';

function reduceReducers(...reducers) {
    return (previous, current) => reducers.reduce((p, r) => r(p, current), previous);
}

const reducers = combineReducers({
    core: combineReducers({
        fetch: fetch('core.fetch'),
        healthStatus: fetch('core.healthStatus'),
        iam: fetch('core.iam'),
        userPreferences: reduceReducers(
            fetch('core.userPreferences'),
            userPreferences('core.userPreferences')
        ),
        version: fetch('core.version'),
        webext: webExt(),
        workingModes: reduceReducers(
            fetch('core.workingModes'),
            workingModes('core.workingModes')
        )
    })
});

export default reducers;
