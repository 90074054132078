import React from "react";
import { Alert } from "antd";
import { Panel } from '@nco-b2c-react/component-panel';

export function CoreWelcome() {
    return (
        <Panel>
            <Alert
                message="Welcome to NMP"
                description="This is the homepage of NMP."
                type="info"
                showIcon />
        </Panel>
    );
}

export default CoreWelcome;