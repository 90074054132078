import React from 'react';
import { Switch } from 'react-router-dom';
import { LegalNotice } from '@nco-b2c-react/component-legal-notice';
import { NotFound } from '@nco-b2c-react/component-not-found';
import { Route, SecureRoute } from '@nco-b2c-react/component-route';
import { Page } from '@nco-b2c-react/component-page';
import { Panel } from '@nco-b2c-react/component-panel';
import { AuthStateSpinner, withAuthProvider } from '@nco-b2c-react/component-iam';
import CoreWelcome from './CoreWelcome';
import queryString from 'query-string';

import '@nco-b2c-react/common-styles/lib/nes.css';

function Root() {
    const helpPageUrl = '/core/resources/help/General/index.html';
    const title = 'NMP Core';

    const getQueryParameters = props => {
        return queryString.parse(props.location.search);
    };

    return (
        <Switch>
            <AuthStateSpinner>
                <Page
                    helpPageUrl={helpPageUrl}
                    versionConfigs={[
                        {
                            applicationName: 'Core Frontend',
                            application: 'core-frontend',
                            url: '/core/version'
                        }
                    ]}>
                    <Switch>
                        <SecureRoute
                            exact
                            path="/core"
                            title={title}>
                            <Panel transparent={true}>
                                <CoreWelcome />
                            </Panel>
                        </SecureRoute>
                        <Route
                            exact
                            path="/core/legal"
                            title={`${title} - Legal Notice & Disclaimer`}>
                            <Panel transparent={true}>
                                <LegalNotice />
                            </Panel>
                        </Route>
                        <Route
                            exact
                            path="/core/404"
                            title={`${title} - 404 not found`}
                            render={props => {
                                const location = getQueryParameters(props).page || props.location;

                                return (
                                    <Panel>
                                        <NotFound location={location} match="/core/" redirect={false} />
                                    </Panel>
                                );
                            }} />
                        <Route
                            title={`${title} - 404 not found`}
                            render={({ location }) => (
                                <Panel transparent={true}>
                                    <Panel>
                                        <NotFound location={location} match="/core/" />
                                    </Panel>
                                </Panel>
                            )} />
                    </Switch>
                </Page>
            </AuthStateSpinner>
        </Switch>
    );
}

export default withAuthProvider(Root, { disabledAuthStateSpinner: true });