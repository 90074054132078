import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";

export default function configureStore() {
    var store = createStore(
        reducers,
        {
            core: {}
        },
        applyMiddleware(thunk)
    );

    var debug = false;

    if (debug) {
        store.subscribe(() => console.log('Store Updated', store.getState()));
    }

    return store;
}
