import React from "react";
import { ConfigProvider } from "antd";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import enUS from "antd/lib/locale-provider/en_US";
import { initOidcConfig } from '@nco-b2c-react/common-utilities';
import Root from "./components/core/Root";
import configureStore from "./store";

const root = createRoot(document.getElementById('root'));

initOidcConfig({
    authority: process.env.REACT_APP_OIDC_ISSUER,
    client_id: process.env.REACT_APP_OIDC_CLIENT_ID
});

root.render(
    <ConfigProvider locale={enUS}>
        <Provider store={configureStore()}>
            <BrowserRouter>
                <Root />
            </BrowserRouter>
        </Provider>
    </ConfigProvider>);
